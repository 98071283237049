// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../../redux/reducers/studies";
import { surveysSelectors } from "../../redux/reducers/surveys";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import Icon from "@mui/material/Icon";
// -------------------------------------------------
// Helper functions
import { translateUnit } from "../../supportFunc/getTicketsForUserAndTimepoint";
// =================================================
// FUNCTIONAL COMPONENT
const VolunteersEditTicket = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "volunteers.Volunteers_EditTicket",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const currentStudy = useSelector((state) =>
    studiesSelectors.selectById(state, props.obj.studyId),
  );
  const studyList = useSelector((state) => studiesSelectors.selectAll(state));
  const surveyEntities = useSelector((state) =>
    surveysSelectors.selectEntities(state),
  );
  // Local state
  const [isPast, setIsPast] = useState(false);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const interpretTimeUnit = (t, u) => {
    let unit;
    switch (u) {
      case "h":
        unit = "hour";
        break;
      case "d":
        unit = "day";
        break;
      case "w":
        unit = "week";
        break;
      case "m":
        unit = "month";
        break;
      default:
        return "";
    }
    if (t === -1) {
      return `indefinate`;
    } else if (t === 0) {
      return "no";
    } else if (t === 1) {
      return `1 ${unit}`;
    } else {
      return `${t} ${unit}s`;
    }
  };
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[key] = value;
      // Calculate new date due and expire
      newState.dateDue =
        newState.availability === -1
          ? null
          : DateTime.fromISO(newState.dateAvailable)
              .plus({
                [translateUnit(newState.availabilityUnit)]:
                  newState.availability,
              })
              .toISO({ includeOffset: false, suppressSeconds: true });
      newState.dateExpire =
        newState.availability === -1
          ? null
          : DateTime.fromISO(newState.dateAvailable)
              .plus({
                [translateUnit(newState.availabilityUnit)]:
                  newState.availability,
              })
              .plus({
                [translateUnit(newState.allowanceUnit)]: newState.allowance,
              })
              .toISO({ includeOffset: false, suppressSeconds: true });
      // Check if the new times are in the past
      if (DateTime.fromISO(newState.dateExpire).diffNow().valueOf() < 0) {
        setIsPast(true);
      } else {
        setIsPast(false);
      }
      return newState;
    });
  };
  // Make the survey select menu items
  const makeSelectMenuItems = () => {
    const itemList = [];
    studyList.forEach((study) => {
      if (
        Object.keys(surveyEntities).filter(
          (key) => surveyEntities[key].folder === study._id,
        ).length > 0
      ) {
        itemList.push(
          <ListSubheader key={study._id}>{study.name}</ListSubheader>,
        );
        Object.keys(surveyEntities)
          .filter((key) => surveyEntities[key].folder === study._id)
          .sort((a, b) =>
            a &&
            b &&
            surveyEntities[a].name.official < surveyEntities[b].name.official
              ? -1
              : 1,
          )
          .forEach((key) => {
            itemList.push(
              <MenuItem key={key} value={key} className="ps-5">
                {surveyEntities[key].name.official}
              </MenuItem>,
            );
          });
      }
    });
    return itemList;
  };
  const getTimepointLabel = (id) => {
    const thisTimepoint = currentStudy.timepointList.find(
      (timepoint) => timepoint._id === props.currentTimepointId,
    );
    if (thisTimepoint && thisTimepoint.label) {
      return thisTimepoint.label;
    } else {
      return "n/a";
    }
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Paper
        className={isXS ? "m-2 px-3 pt-3" : "m-2 px-3 pt-3 fix-width-625px"}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">{t("Ticket information")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem divider>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="STUDY"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <Typography paragraph={false}>
                      {currentStudy.acronym}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="TIMEPOINT"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <Typography paragraph={false}>
                      {getTimepointLabel(props.currentTimepointId)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="LEVEL"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <TextField
                      hiddenLabel
                      type="number"
                      variant="filled"
                      size="small"
                      color="secondary"
                      value={props.obj.level}
                      onChange={(e) => handleSetObj("level", e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        className={isXS ? "m-2 px-3 pt-3" : "m-2 px-3 pt-3 fix-width-625px"}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" className="mb-2">
              {t("Survey")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              hiddenLabel
              name="surveyId"
              size="small"
              variant="filled"
              value={
                surveyEntities[props.obj.surveyId] ? props.obj.surveyId : ""
              }
              color="secondary"
              onChange={(e) => handleSetObj(e.target.name, e.target.value)}
            >
              {/* ----------------------------------------------- */}
              {/* STANDARDIZED SURVEYS */}
              <ListSubheader>{t("Standardized surveys")}</ListSubheader>
              {Object.keys(surveyEntities)
                .filter((key) => surveyEntities[key].folder === "standardized")
                .sort((a, b) =>
                  a &&
                  b &&
                  surveyEntities[a].name.official <
                    surveyEntities[b].name.official
                    ? -1
                    : 1,
                )
                .map((key) => (
                  <MenuItem key={key} value={key} className="ps-5">
                    {surveyEntities[key].name.official}
                  </MenuItem>
                ))}
              {/* ----------------------------------------------- */}
              {/* MAP OVER ALL STUDIES */}
              {makeSelectMenuItems()}
            </Select>
            <List>
              <ListItem divider>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="TO BE COMPLETED"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <ToggleButtonGroup
                      value={props.obj.completer}
                      exclusive
                      size="small"
                      onChange={(e, newValue) =>
                        newValue && handleSetObj("completer", newValue)
                      }
                    >
                      <ToggleButton value="participant">
                        By Participant
                      </ToggleButton>
                      <ToggleButton value="investigator">
                        By Investigator
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
            <FormControlLabel
              control={
                <Switch
                  checked={props.obj.isCompleted}
                  color="secondary"
                  onChange={(e) =>
                    handleSetObj("isCompleted", e.target.checked)
                  }
                />
              }
              label={
                props.obj.isCompleted ? (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ fontSize: "0.8125rem" }}
                  >
                    {t("Is completed")}
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ fontSize: "0.8125rem" }}
                  >
                    {t("Is not completed")}
                  </Typography>
                )
              }
              className="ms-2 mb-2"
            />
            <br />
            <FormControlLabel
              control={
                <Switch
                  checked={props.obj.remainVisible}
                  color="secondary"
                  onChange={(e) =>
                    handleSetObj("remainVisible", e.target.checked)
                  }
                />
              }
              label={
                props.obj.remainVisible ? (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ fontSize: "0.8125rem" }}
                  >
                    Remains in task list upon completion
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ fontSize: "0.8125rem" }}
                  >
                    Disapears from task list upon completion
                  </Typography>
                )
              }
              className="ms-2 mb-2"
            />
            {props.obj.remainVisible && (
              <>
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.obj.remainEditable}
                      color="secondary"
                      onChange={(e) =>
                        handleSetObj("remainEditable", e.target.checked)
                      }
                    />
                  }
                  label={
                    props.obj.remainEditable ? (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: "0.8125rem" }}
                      >
                        Remains editable upon completion
                      </Typography>
                    ) : (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: "0.8125rem" }}
                      >
                        Is no longer editable upon completion
                      </Typography>
                    )
                  }
                  className="ms-2 mb-2"
                />
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper
        className={
          isXS ? "m-2 mb-3 px-3 pt-3" : "m-2 mb-3 px-3 pt-3 fix-width-625px"
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">{t("Date and time")}</Typography>
            {isPast && (
              <Alert severity="warning" className="mt-2">
                {t("The ticket expiry date is in the past.")}
              </Alert>
            )}
          </Grid>

          <Grid item xs={12} className="mb-2">
            <Typography variant="caption">
              {`Occurs on ${DateTime.fromISO(props.obj.dateAvailable).toFormat(
                "cccc d LLLL yyyy 'at' H:mm",
              )}, with ${interpretTimeUnit(
                props.obj.availability,
                props.obj.availabilityUnit,
              )} availability${
                props.obj.availability > -1
                  ? ` and ${interpretTimeUnit(
                      props.obj.allowance,
                      props.obj.allowanceUnit,
                    )}
              allowance`
                  : ""
              }.`}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <List disablePadding>
              <ListItem divider>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="DATE AVAILABLE"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <MobileDateTimePicker
                      slotProps={{
                        actionBar: {
                          actions: ["today", "cancel", "accept"],
                        },
                        textField: {
                          hiddenLabel: true,
                          variant: "filled",
                          size: "small",
                          color: "secondary",
                        },
                      }}
                      value={DateTime.fromISO(props.obj.dateAvailable)}
                      ampm={false}
                      format="ccc d LLL yyyy HH:mm"
                      onChange={() => null}
                      onAccept={(newValue) => {
                        handleSetObj(
                          "dateAvailable",
                          newValue.toISO({
                            suppressSeconds: true,
                            includeOffset: false,
                          }),
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <Grid container alignItems="center" className="mt-3 ps-3">
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.obj.availability === -1}
                        color="secondary"
                        onChange={(e) =>
                          handleSetObj(
                            "availability",
                            e.target.checked ? -1 : 1,
                          )
                        }
                      />
                    }
                    label={
                      props.obj.availability === -1 ? (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ fontSize: "0.8125rem" }}
                        >
                          {t("Available indefinitely")}
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ fontSize: "0.8125rem" }}
                        >
                          {t("Limited availability")}
                        </Typography>
                      )
                    }
                    className="py-2"
                  />
                </Grid>
                <Grid item xs={3}>
                  {props.obj.availability !== -1 && (
                    <TextField
                      fullWidth
                      label={t("availability")}
                      type="number"
                      name="availability"
                      variant="filled"
                      size="small"
                      value={props.obj.availability}
                      color="secondary"
                      InputProps={{
                        inputProps: { min: 1 },
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon
                              className="me-2 align-middle"
                              fontSize="small"
                            >
                              lock_clock
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                      className="mb-2 pe-2 answer-text-green"
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) !== null &&
                          parseInt(e.target.value) !== undefined &&
                          !isNaN(parseInt(e.target.value)) &&
                          parseInt(e.target.value) > 0
                        ) {
                          handleSetObj(
                            "availability",
                            parseInt(e.target.value),
                          );
                        }
                      }}
                      onWheel={(e) => e.target.blur()}
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  {props.obj.availability !== -1 && (
                    <ToggleButtonGroup
                      value={props.obj.availabilityUnit}
                      exclusive
                      size="small"
                      className="mb-2"
                      onChange={(e, newValue) =>
                        newValue && handleSetObj("availabilityUnit", newValue)
                      }
                    >
                      <ToggleButton value="h">{t("h")}</ToggleButton>
                      <ToggleButton value="d">{t("d")}</ToggleButton>
                      <ToggleButton value="w">{t("w")}</ToggleButton>
                      <ToggleButton value="m">{t("m")}</ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Grid>
              </Grid>
              {props.obj.availability !== -1 && (
                <Grid container alignItems="center" className="ps-3">
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.obj.allowance !== 0}
                          color="secondary"
                          onChange={(e) =>
                            handleSetObj("allowance", e.target.checked ? 1 : 0)
                          }
                        />
                      }
                      label={
                        props.obj.allowance !== 0 ? (
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{ fontSize: "0.8125rem" }}
                          >
                            {t("Can be overdue by")}
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{ fontSize: "0.8125rem" }}
                          >
                            {t("Cannot be overdue")}
                          </Typography>
                        )
                      }
                      className="py-2"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {props.obj.allowance !== 0 && (
                      <TextField
                        fullWidth
                        label={t("allowance")}
                        type="number"
                        name="allowance"
                        variant="filled"
                        size="small"
                        value={props.obj.allowance}
                        color="secondary"
                        InputProps={{
                          inputProps: { min: 1 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon
                                className="me-2 align-middle"
                                fontSize="small"
                              >
                                lock_clock
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                        className="mb-2 pe-2 answer-text-green"
                        onChange={(e) => {
                          if (
                            parseInt(e.target.value) !== null &&
                            parseInt(e.target.value) !== undefined &&
                            !isNaN(parseInt(e.target.value)) &&
                            parseInt(e.target.value) > 0
                          ) {
                            handleSetObj("allowance", parseInt(e.target.value));
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {props.obj.allowance !== 0 && (
                      <ToggleButtonGroup
                        value={props.obj.allowanceUnit}
                        exclusive
                        size="small"
                        className="mb-2"
                        onChange={(e, newValue) =>
                          newValue && handleSetObj("allowanceUnit", newValue)
                        }
                      >
                        <ToggleButton value="h">{t("h")}</ToggleButton>
                        <ToggleButton value="d">{t("d")}</ToggleButton>
                        <ToggleButton value="w">{t("w")}</ToggleButton>
                        <ToggleButton value="m">{t("m")}</ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  </Grid>
                </Grid>
              )}
            </List>
          </Grid>
        </Grid>
      </Paper>
      {props.canBeDeleted && (
        <div className="p-2 d-flex align-items-end">
          <Button
            fullWidth
            type="button"
            variant="contained"
            color="error"
            onClick={props.handleDeleteObj}
          >
            {t("Remove ticket")}
          </Button>
        </div>
      )}
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersEditTicket;