// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Components
import AppRoot from "../components/App_Root";
import ScrollColumn from "../components/App_ScrollColumn";
import ContentPanel from "../components/App_ContentPanel";
import TopDrawer from "../components/App_TopDrawer";
import FormRoot from "../components/Form_Root";
import SurveysColumnContent from "../components/Surveys_ColumnContent";
import SurveysHeader from "../components/Surveys_Header";
import SurveysContent from "../components/Surveys_Content";
// -------------------------------------------------
// Redux
import { togglePrimDrawer, toggleSecDrawer } from "../redux/reducers/ui";
import {
  fetchSurveyList,
  patchCurrentSurvey,
  deleteCurrentSurvey,
  surveysSelectors,
} from "../redux/reducers/surveys";
import { fetchStudyList, studiesSelectors } from "../redux/reducers/studies";
import { setCurrentTicketByObject } from "../redux/reducers/tickets";
import { resetFormState } from "../redux/reducers/form";
// -------------------------------------------------
// Helper functions
import { defaultSurvey } from "../supportFunc/defaultSurveys";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// -------------------------------------------------
// Icons
import AddCircle from "@mui/icons-material/AddCircle";
import Star from "@mui/icons-material/Star";
import School from "@mui/icons-material/School";
// =================================================
// FUNCTIONAL COMPONENT
const Surveys = () => {
  const { t, i18n } = useTranslation("pages", { keyPrefix: "Surveys" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Local state
  const [surveyId, setSurveyId] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [print2pdf, setPrint2pdf] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const primDrawerIsOpen = useSelector((state) => state.ui.primDrawerIsOpen);
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentSurvey = useSelector((state) =>
    surveysSelectors.selectById(state, surveyId)
  );
  const studyList = useSelector((state) => studiesSelectors.selectAll(state));
  const surveysStatus = useSelector((state) => state.surveys.status);
  const studiesStatus = useSelector((state) => state.studies.status);
  // -----------------------------------------------
  // Browser URL location
  const location = useLocation();
  const navigate = useNavigate();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Upon render, get the surveyList and set the redux state
  useEffect(() => {
    // Fetch all surveys from DB if not done yet
    if (surveysStatus === "idle" || surveysStatus === "partial") {
      dispatch(fetchSurveyList({ requestingUser: currentAuth }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Fetch all studies from DB if not done yet
    if (studiesStatus === "idle") {
      dispatch(fetchStudyList({ requestingUser: currentAuth }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Get the survey-id from URL and get the current survey
    if (surveysStatus === "succeeded") {
      const searchParams = new URLSearchParams(location.search);
      setSurveyId(searchParams.get("surveyId"));
      if (currentSurvey && !primDrawerIsOpen) {
        dispatch(togglePrimDrawer({ isOpen: true }));
      } else if (primDrawerIsOpen) {
        dispatch(togglePrimDrawer({ isOpen: false }));
      }
    }
  }, [surveyId, currentSurvey, surveysStatus, location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (surveysStatus === "succeeded") {
      dispatch(fetchSurveyList({ requestingUser: currentAuth }));
    }
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  // -------------------------------------------------
  // Preview the survey
  const handlePreviewSurvey = () => {
    dispatch(
      setCurrentTicketByObject({
        ticket: {
          _id: uuid(),
          userId: null,
          studyId: null,
          timepointId: null,
          measurementId: null,
          groupId: null,
          surveyId: currentSurvey && currentSurvey._id,
          completer: "participant",
          remainVisible: false,
          remainEditable: false,
          level: "1",
          repeat: 1,
          interval: 1,
          intervalUnit: "h",
          availability: -1,
          availabilityUnit: "h",
          allowance: 0,
          allowanceUnit: "h",
          responseCollection: "previewResponses",
          responseId: uuid(),
          entryNumber: 1,
          dateAvailable: "2000-01-01T00:00",
          dateDue: null,
          dateExpire: null,
          hasStarted: false,
          isCompleted: false,
          viewIdx: 0,
        },
      })
    );
    dispatch(resetFormState());
    dispatch(toggleSecDrawer({ isOpen: true, id: "surveys-preview" }));
  };
  // -----------------------------------------------
  // Adds new survey to the database and sets the current survey to this new survey
  const handleAddNewSurvey = async (folder) => {
    const defaultPage = defaultSurvey.page;
    defaultPage._id = uuid();
    defaultPage.gridContainerList[0]._id = uuid();
    defaultPage.gridContainerList[0].gridItemList[0]._id = uuid();
    // survey id does not exist yet, so 'patch' will add new document
    const res = await dispatch(
      patchCurrentSurvey({
        socket,
        requestingUser: currentAuth,
        body: {
          data: {
            _id: uuid(),
            userId: currentUser._id,
            folder,
            name: {
              official: "Official name",
              display: "Display name",
            },
            acronym: "XYZ",
            pageList: [defaultPage],
          },
        },
      })
    );
    if (res.payload) {
      navigate(`/surveys?surveyId=${res.payload.survey._id}`, {
        replace: true,
      });
    }
  };
  // -----------------------------------------------
  // Handles copying a survey
  const handleCopySurvey = async (survey) => {
    // Deep clone the object
    let newSurvey = JSON.parse(JSON.stringify(survey));
    newSurvey._id = uuid();
    newSurvey.userId = currentUser._id;
    newSurvey.isLive = false;
    newSurvey.name.official = `${newSurvey.name.official} (copy)`;
    // survey id does not exist yet, so 'patch' will add new document
    const res = await dispatch(
      patchCurrentSurvey({
        socket,
        requestingUser: currentAuth,
        body: {
          data: newSurvey,
        },
      })
    );
    if (res.payload) {
      navigate(`/surveys?surveyId=${res.payload.survey._id}`, {
        replace: true,
      });
    }
  };
  const handleMoveSurvey = async (folder) => {
    await dispatch(
      patchCurrentSurvey({
        socket,
        requestingUser: currentAuth,
        body: { data: { ...currentSurvey, folder } },
      })
    );
  };
  const handleDeleteSurvey = async (folder) => {
    await dispatch(
      deleteCurrentSurvey({
        socket,
        requestingUser: currentAuth,
        surveyId: currentSurvey._id,
      })
    );
    navigate("/surveys", { replace: true });
  };
  // ===============================================
  // SUB-COMPONENTS
  // -----------------------------------------------
  const ColumnHeader =
    currentUser &&
    (currentUser.primaryRole === "admin" ||
      currentUser.primaryRole === "superuser") ? (
      <>
        <Tooltip arrow title={t("Add new survey")} placement="top">
          <IconButton
            color="inherit"
            onClick={(e) => {
              setMenuIsOpen(true);
              setMenuAnchor(e.currentTarget);
            }}
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={menuAnchor}
          open={menuIsOpen}
          onClose={() => {
            setMenuIsOpen(false);
            setMenuAnchor(null);
          }}
        >
          <MenuItem
            onClick={() => {
              handleAddNewSurvey("standardized");
              setMenuIsOpen(false);
            }}
          >
            <ListItemIcon>
              <Star fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("Standardized surveys")}</ListItemText>
          </MenuItem>
          {studyList && studyList.length > 0 && (
            <ListSubheader>{t("Study folders")}</ListSubheader>
          )}
          {studyList.map((study) => (
            <MenuItem
              key={study._id}
              onClick={() => {
                handleAddNewSurvey(study._id);
                setMenuIsOpen(false);
              }}
            >
              <ListItemIcon>
                <School fontSize="small" />
              </ListItemIcon>
              <ListItemText>{study.acronym}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </>
    ) : null;
  // ===============================================
  // RENDER COMPONENT
  return (
    <AppRoot header={ColumnHeader}>
      <Dialog
        open={modalOpen}
        scroll="body"
        fullScreen={true}
        onClose={() => setModalOpen(false)}
      >
        <DialogActions>
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              window.print();
              setModalOpen(false);
            }}
          >
            {t("Save PDF")}
          </Button>
        </DialogActions>
        <div
          id={
            currentSurvey && currentSurvey.name && currentSurvey.name.official
          }
        >
          <SurveysContent currentSurvey={currentSurvey} print2pdf={true} />
        </div>
      </Dialog>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE} | {t("Surveys")}
        </title>
      </Helmet>
      <TopDrawer id="surveys-preview" title={t("Preview")}>
        <div className={isXS ? "w-100 p-3" : "fix-width-92vw p-3"}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={7}>
              {currentSurvey && <FormRoot />}
            </Grid>
          </Grid>
        </div>
      </TopDrawer>
      <Grid container className="w-100" wrap="nowrap">
        {/* Scroll column */}
        <ScrollColumn
          header={ColumnHeader}
          isLoading={surveysStatus === "loading"}
        >
          <SurveysColumnContent
            currentSurveyId={currentSurvey && currentSurvey._id}
            studyList={studyList}
          />
        </ScrollColumn>
        {/* Content panel */}
        <ContentPanel
          id={
            currentSurvey && currentSurvey.name && currentSurvey.name.official
              ? currentSurvey.name.official
              : ""
          }
          title={
            currentSurvey && currentSurvey.name && currentSurvey.name.official
              ? currentSurvey.name.official
              : ""
          }
          isLoading={surveysStatus === "loading"}
          buttons={
            <SurveysHeader
              disabled={currentSurvey && currentSurvey.folder === "archive"}
              currentSurveyId={currentSurvey && currentSurvey._id}
              currentSurveyTitle={currentSurvey && currentSurvey.name.official}
              studyList={studyList}
              handleSetModalOpen={setModalOpen}
              handleSetPrint2pdf={setPrint2pdf}
              handlePreviewSurvey={handlePreviewSurvey}
              handleCopySurvey={handleCopySurvey}
              handleMoveSurvey={handleMoveSurvey}
              handleDeleteSurvey={handleDeleteSurvey}
            />
          }
        >
          <SurveysContent currentSurvey={currentSurvey} print2pdf={print2pdf} />
        </ContentPanel>
      </Grid>
    </AppRoot>
  );
};
// =================================================
// EXPORT COMPONENT
export default Surveys;
