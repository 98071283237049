// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ItemQuestion from "./ItemQuestion";
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const RadioGridItem = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);

  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias)
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id]
  );
  const liveValidation = useSelector((state) => state.form.liveValidation);
  // -----------------------------------------------
  // Setup the variable class for the table cell first colum width
  const useStyles = makeStyles(() => ({
    firstColumn: {
      padding: "0px",
      paddingRight: "8px",
      width: props.firstColumnWidth,
    },
  }));
  const classes = useStyles();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // If the item is now disabled, remove any prior response
    if (!isEnabled) {
      handleSetSingleResponse(props.alias, null);
      return; // We can return since the component will unmount which will initialize the validation item
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value })
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value })
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // If the item is disabled, we don't have to set 'hasStarted' or do validation
    if (!isEnabled) {
      return;
    }
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      })
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // RENDER COMPONENT
  return isEnabled || props.doShow ? (
    <TableRow 
      className={`answer-row ${liveValidation && localValidation?.isInvalid ? "bg-list-item-invalid" : ""}`}
    >

      <TableCell className={classes.firstColumn}>
        <ArticleFormControl
          article={props.article}
          required={!props.articleRequired && props.required}
          isInvalid={localValidation && localValidation.isInvalid}
          validationMsg={localValidation && localValidation.msg[props.alias]}
          helperTextAlign="text-start"
        >

          <ItemQuestion
            name={props.alias}
            item={props.article}
            required={!props.articleRequired && props.required}
            text={props.article.text}
            doShow={props.doShow}
            dependencies={props.dependencies}
            aliasList={props.aliasList}
            scoring={props.scoring}
            alias={props.alias}
            justifyContent="flex-start"
            alignItems="center"
          />
        </ArticleFormControl>
      </TableCell>
      {props.answerList.map((answer, i) => (
        <TableCell
          key={`${props.alias}_radio-${i}`}
          className="py-1 px-0 text-center align-middle text-nowrap"
          onClick={() =>
            !props.uneditable &&
            handleSetSingleResponse(
              props.alias,
              props.article.reverseValue
                ? props.answerList[props.answerList.length - i - 1].value
                : answer.value
            )
          }
        >
          <Radio
            disabled={props.uneditable}
            name={`${props.alias}_radio-${answer.value}`}
            checked={
              props.article.reverseValue
                ? value ===
                  props.answerList[props.answerList.length - i - 1].value
                : value === answer.value
            }
            value={
              props.article.reverseValue
                ? props.answerList[props.answerList.length - i - 1].value
                : answer.value
            }
            color="secondary"
          />
          {props.doShow && (
            <Chip
              label={
                <Typography variant="overline" color="textSecondary">
                  {props.article.reverseValue
                    ? props.answerList[props.answerList.length - i - 1].value
                    : answer.value}
                </Typography>
              }
              variant={props.article.reverseValue ? "outlined" : "filled"}
              size="small"
              className="me-2"
            />
          )}
        </TableCell>
      ))}
    </TableRow>
  ) : null;
};
// =================================================
// EXPORT COMPONENT
export default RadioGridItem;
