// ########################################
// IMPORT
// ========================================
// Dependencies
// ----------------------------------------
import {
  configureStore,
  combineReducers,
  applyMiddleware,
} from "@reduxjs/toolkit";
import { composeWithDevTools } from '@redux-devtools/extension';
// Reducers
import uiReducer from "./reducers/ui";
import userReducer from "./reducers/user";
import consumersReducer from "./reducers/consumers";
import notificationsReducer from "./reducers/notifications";
import supervisionReducer from "./reducers/supervision";
import formReducer from "./reducers/form";
import ticketsReducer from "./reducers/tickets";
import studiesReducer from "./reducers/studies";
import surveysReducer from "./reducers/surveys";
import taskResponsesReducer from "./reducers/taskResponses";
import previewResponsesReducer from "./reducers/previewResponses";
import conversationsReducer from "./reducers/conversations";
import messagesReducer from "./reducers/messages";
import webpagesReducer from "./reducers/webpages";
import publicWebpagesReducer from "./reducers/publicWebpages";
import publicTicketsReducer from "./reducers/publicTickets";
import publicSurveysReducer from "./reducers/publicSurveys";
// ########################################
// CODE
// ----------------------------------------
// Combine reducers
const combinedReducers = combineReducers({
  ui: uiReducer,
  user: userReducer,
  consumers: consumersReducer,
  notifications: notificationsReducer,
  supervision: supervisionReducer,
  form: formReducer,
  tickets: ticketsReducer,
  studies: studiesReducer,
  surveys: surveysReducer,
  taskResponses: taskResponsesReducer,
  previewResponses: previewResponsesReducer,
  conversations: conversationsReducer,
  messages: messagesReducer,
  webpages: webpagesReducer,
  publicWebpages: publicWebpagesReducer,
  publicTickets: publicTicketsReducer,
  publicSurveys: publicSurveysReducer,
});
// ----------------------------------------
// Create root reducer, and reset state with the action "store/init"
const rootReducer = (state, action) => {
  if (action.type === "store/init") {
    state = {
      publicSurveys: state.publicSurveys,
      publicTickets: state.publicTickets,
      publicWebpages: state.publicWebpages,
    };
  }
  return combinedReducers(state, action);
};
// ########################################
// SETUP THE STORE
export const store = configureStore(
  {
    reducer: rootReducer,
  },
  composeWithDevTools(applyMiddleware())
);
