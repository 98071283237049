// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Component elements
import VolunteersContentMeasurementItem from "./Volunteers_ContentMeasurementItem";
import VolunteersEditTicket from "./Volunteers_EditTicket";
import VolunteersEditTicketSeries from "./Volunteers_EditTicketSeries";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Contexts
import { useAuth } from "../../contexts/auth";
import { useSocket } from "../../contexts/socket";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
import { studiesSelectors } from "../../redux/reducers/studies";
import {
  patchCurrentTicket,
  postTicketList,
  deleteCurrentTicket,
  ticketsSelectors,
} from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// -----------------------------------------------
// Icons
import Save from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
// -------------------------------------------------
// Support functions
import { getStudyEnrollmentTimepoints } from "../../supportFunc/getStudyEnrollmentTimepoints";
import {
  getTicketsForUserAndTimepoint,
  getTicketsForMeasurement,
} from "../../supportFunc/getTicketsForUserAndTimepoint";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const VolunteersContentTimepoints = (props) => {
  // ===============================================
  // TRANSLATION
  // -----------------------------------------------
  const { t } = useTranslation("components", {
    keyPrefix: "volunteers.Volunteers_ContentTimepoints",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [isOpenDateSelector, setIsOpenDateSelector] = useState(false); // Form dialog state for date selector
  const [newDate, setNewDate] = useState(null); // To change the enrollment/timepoint dates
  const [method, setMethod] = useState(""); // To indicate whether timepoint dates are 'assigned' or 'reassigned'
  const [tickets, setTickets] = useState(null); // To add or edit a single ticket or ticket series
  const [isTicketSeries, setIsTicketSeries] = useState(false);
  const [isNewTicket, setIsNewTicket] = useState(false);
  const [currentTimepointId, setCurrentTimepointId] = useState(
    props.timepointList &&
      props.timepointList.length > 0 &&
      props.timepointList[0]._id,
  );
  const [assortedTicketList, setAssortedTicketList] = useState({});
  const [anchorElAddMenu, setAnchorElAddMenu] = useState(null);
  const isOpenAddMenu = Boolean(anchorElAddMenu);
  const [anchorElTimepointGroup, setAnchorElTimepointGroup] = useState(null);
  const isOpenTimepointGroup = Boolean(anchorElTimepointGroup);

  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentUserId = useSelector(
    (state) => state.user.currentUser && state.user.currentUser._id,
  );
  const ticketsStatus = useSelector((state) => state.tickets.status);
  const consumersStatus = useSelector((state) => state.consumers.status);
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const currentStudyId = useSelector((state) => state.consumers.currentStudyId);
  const currentStudy = useSelector(
    (state) =>
      currentStudyId && studiesSelectors.selectById(state, currentStudyId),
  );
  const ticketAvailabilityList = useSelector(
    (state) => state.tickets.ticketAvailabilityList,
  );
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const isCompletedTimepoint = (tpId) => {
    return (
      ticketList
        .filter((ticket) => ticket.timepointId === tpId)
        .every((ticket) => ticket.isCompleted) &&
      ticketList.filter((ticket) => ticket.timepointId === tpId).length > 0
    );
  };
  // -----------------------------------------------
  const isActiveTimepoint = (tpId) => {
    const hasAssignedTimepoint =
      props.currentConsumer.studyEnrollmentList[0].timepointAssignmentList &&
      props.currentConsumer.studyEnrollmentList[0].timepointAssignmentList[0];
    if (!hasAssignedTimepoint) {
      return false;
    }

    const tpa =
      props.currentConsumer.studyEnrollmentList[0].timepointAssignmentList.find(
        (tp) => tp.timepointId === tpId,
      );
    if (!tpa || !tpa.startDate || tpa.startDate === null) {
      return false;
    }
    return DateTime.fromISO(tpa.startDate).diffNow() < 0;
  };
  // -----------------------------------------------
  const getTimepointAssignmentStartDate = (tpId) => {
    const tpa =
      props.currentConsumer.studyEnrollmentList[0].timepointAssignmentList &&
      props.currentConsumer.studyEnrollmentList[0].timepointAssignmentList.find(
        (t) => t.timepointId === tpId,
      );
    if (!tpa || !tpa.startDate || tpa.startDate == null) {
      return null;
    }
    return DateTime.fromISO(tpa.startDate);
  };
  // -----------------------------------------------
  const isAssignedToTimepointGroup = (groupIds) => {
    if (!groupIds || groupIds.length === 0) {
      return true;
    }
    const gra =
      props.currentConsumer.studyEnrollmentList[0].groupAssignmentList &&
      props.currentConsumer.studyEnrollmentList[0].groupAssignmentList[0];
    if (!gra) {
      return false;
    }
    return groupIds.some((gid) => gid === gra.groupId);
  };
  // -----------------------------------------------
  const handleToggleAddMenu = (el) => {
    setAnchorElAddMenu(el);
  };
  // -----------------------------------------------
  // Assigns a date to a timepoint and generates tickets
  const handleAssignTimepointDate = () => {
    // Init
    setIsOpenDateSelector(false);
    let newTicketList = [];
    let appendTickets;
    // Deep copy the consumer object
    let newConsumer = JSON.parse(JSON.stringify(props.currentConsumer));
    // Find the index of the new timepoint
    const idx =
      newConsumer.studyEnrollmentList[0].timepointAssignmentList.findIndex(
        (t) => t.timepointId === currentTimepointId,
      );
    // Assign the new date
    newConsumer.studyEnrollmentList[0].timepointAssignmentList[idx].startDate =
      newDate.toISODate();
    // Now that we know this timepoint's date, we can calculate the start date of the other timepoints
    newConsumer.studyEnrollmentList[0].timepointAssignmentList =
      getStudyEnrollmentTimepoints(
        currentStudy,
        newConsumer.studyEnrollmentList[0].enrollmentDate,
        newConsumer.studyEnrollmentList[0].timepointAssignmentList,
      );
    // Create new ticket list
    for (
      var i = 0;
      i < newConsumer.studyEnrollmentList[0].timepointAssignmentList.length;
      i++
    ) {
      if (
        !props.currentConsumer.studyEnrollmentList[0].timepointAssignmentList[i]
          .startDate
      ) {
        appendTickets = getTicketsForUserAndTimepoint(
          newConsumer,
          currentStudy,
          newConsumer.studyEnrollmentList[0].timepointAssignmentList[i]
            .timepointId,
          "taskResponses",
        );
        if (appendTickets) {
          newTicketList = [...newTicketList, ...appendTickets];
        }
      }
    }
    newTicketList.length > 0 &&
      dispatch(
        postTicketList({
          socket,
          requestingUser: currentAuth,
          body: {
            data: newTicketList,
            meta: { userId: currentUserId, ticketsToCreate: "new" },
          },
        }),
      );
    // Finally update the consumer document
    props.handlePatchCurrentConsumer(newConsumer);
  };
  // -----------------------------------------------
  // Assigns a date to a timepoint and generates tickets
  const handleReassignTimepointDate = () => {
    // Init
    setIsOpenDateSelector(false);
    let newTicketList, existingTicket;
    // Deep copy the consumer object
    let newConsumer = JSON.parse(JSON.stringify(props.currentConsumer));
    // Find the index of the new timepoint
    const idx =
      newConsumer.studyEnrollmentList[0].timepointAssignmentList.findIndex(
        (t) => t.timepointId === currentTimepointId,
      );
    // Assign the new date
    newConsumer.studyEnrollmentList[0].timepointAssignmentList[idx].startDate =
      newDate.toISODate();
    // Get new ticket list for this timepoint
    newTicketList = getTicketsForUserAndTimepoint(
      newConsumer,
      currentStudy,
      newConsumer.studyEnrollmentList[0].timepointAssignmentList[idx]
        .timepointId,
      "taskResponses",
    );

    // Check if there are any new tickets to be created
    if (!newTicketList) {
      return;
    }

    // For each of the new tickets, find its existing equivalent and patch it
    newTicketList.forEach(async (ticket) => {
      existingTicket = ticketList
        .filter((ticket) => ticket.userId === props.currentConsumer._id)
        .find(
          (t) =>
            t.userId === ticket.userId &&
            t.studyId === ticket.studyId &&
            t.surveyId === ticket.surveyId &&
            t.timepointId === ticket.timepointId &&
            t.measurementId === ticket.measurementId &&
            (t.entryNumber === ticket.entryNumber || t.repeat === -1),
        );
      if (!existingTicket) {
        return;
      }
      existingTicket = JSON.parse(JSON.stringify(existingTicket));
      existingTicket.dateAvailable = ticket.dateAvailable;
      existingTicket.dateDue = ticket.dateDue;
      existingTicket.dateExpire = ticket.dateExpire;
      // Now patch the existing ticket
      await dispatch(
        patchCurrentTicket({
          socket,
          requestingUser: currentAuth,
          body: {
            data: existingTicket,
          },
        }),
      );
    });
    // Finally update the consumer document
    props.handlePatchCurrentConsumer(newConsumer);
  };
  // -----------------------------------------------
  // Intializes a new ticket for editing
  const handleAddTicket = (tpId) => {
    setIsNewTicket(true);
    setIsTicketSeries(false);
    setTickets({
      _id: uuid(),
      userId: props.currentConsumer._id,
      studyId: currentStudyId,
      surveyId: null,
      timepointId: tpId,
      measurementId: uuid(),
      responseId: uuid(),
      completer: "participant",
      remainVisible: false,
      remainEditable: false,
      level:
        ticketList.filter(
          (ticket) => ticket.userId === props.currentConsumer._id,
        ).length > 0
          ? Math.max(
              ...ticketList
                .filter((ticket) => ticket.userId === props.currentConsumer._id)
                .map((ticket) => ticket.level),
            )
          : 1,
      responseCollection: "taskResponses",
      repeat: 1,
      interval: 1,
      intervalUnit: "d",
      availability: -1,
      availabilityUnit: "d",
      allowance: 0,
      allowanceUnit: "d",
      dateAvailable: DateTime.now().startOf("minute").toISO({
        includeOffset: false,
        suppressSeconds: true,
        suppressMilliseconds: true,
      }),
      dateDue: null,
      dateExpire: null,
      hasStarted: false,
      isCompleted: false,
      viewIdx: 0,
    });
    dispatch(
      toggleSecDrawer({
        isOpen: true,
        id: "volunteers_content-timepoints",
      }),
    );
  };
  // -----------------------------------------------
  // Opens the drawer for editing an existing ticket
  const handleEditTicket = (item, type) => {
    let newTickets, timepointStartDate, ticketSeriesStartDate;
    switch (type) {
      case "occurence":
        setIsNewTicket(false);
        setIsTicketSeries(false);
        newTickets = ticketList.find((ticket) => ticket._id === item._id);
        break;
      case "series":
        setIsNewTicket(false);
        setIsTicketSeries(true);
        ticketSeriesStartDate = ticketList
          .filter(
            (ticket) =>
              ticket.userId === item.userId &&
              ticket.measurementId === item.measurementId,
          )
          .sort((a, b) => a.entryNumber - b.entryNumber)[0].dateAvailable;
        timepointStartDate =
          props.currentConsumer.studyEnrollmentList[0].timepointAssignmentList.find(
            (tp) => tp.timepointId === item.timepointId,
          ).startDate;
        newTickets = {
          userId: item.userId,
          studyId: item.studyId,
          timepointId: item.timepointId,
          measurementId: item.measurementId,
          timepointStartDate,
          groupId: item.groupId,
          surveyId: item.surveyId,
          completer: item.completer,
          remainVisible: item.remainVisible,
          remainEditable: item.remainEditable,
          level: item.level,
          delay:
            1 +
            DateTime.fromISO(ticketSeriesStartDate)
              .diff(DateTime.fromISO(timepointStartDate))
              .valueOf() /
              (24 * 60 * 60 * 1000),
          repeat: item.repeat,
          interval: item.interval,
          intervalUnit: item.intervalUnit,
          availability: item.availability,
          availabilityUnit: item.availabilityUnit,
          allowance: item.allowance,
          allowanceUnit: item.allowanceUnit,
          responseCollection: item.responseCollection,
        };
        break;
      default:
        return;
    }
    setIsNewTicket(false);
    setCurrentTimepointId(item.timepointId);
    setTickets(newTickets);
    dispatch(
      toggleSecDrawer({
        isOpen: true,
        id: "volunteers_content-timepoints",
      }),
    );
  };
  // -----------------------------------------------
  // Patch a ticket object
  const handlePatchTicket = () => {
    let existingTickets;
    let someTicketsToBeDeleted = false;
    let newTickets = { ...tickets };
    if (isTicketSeries) {
      newTickets = getTicketsForMeasurement(tickets);
      existingTickets = JSON.parse(
        JSON.stringify(
          ticketList.filter(
            (t) =>
              t.userId === tickets.userId &&
              t.measurementId === tickets.measurementId,
          ),
        ),
      );
      if (tickets.repeat === -1) {
        // This is an indefinately repeating ticket, so update the scheduling and patch
        existingTickets = JSON.parse(JSON.stringify(existingTickets[0]));
        existingTickets.completer = tickets.completer;
        existingTickets.remainVisible = tickets.remainVisible;
        existingTickets.remainEditable = tickets.remainEditable;
        existingTickets.level = tickets.level;
        existingTickets.delay = tickets.delay;
        existingTickets.interval = tickets.interval;
        existingTickets.intervalUnit = tickets.intervalUnit;
        existingTickets.availability = tickets.availability;
        existingTickets.availabilityUnit = tickets.availabilityUnit;
        existingTickets.allowance = tickets.allowance;
        existingTickets.allowanceUnit = tickets.allowanceUnit;
        existingTickets.dateAvailable = newTickets[0].dateAvailable;
        existingTickets.dateDue = newTickets[0].dateDue;
        existingTickets.dateExpire = newTickets[0].dateExpire;
        dispatch(
          patchCurrentTicket({
            socket,
            requestingUser: currentAuth,
            body: { data: existingTickets },
          }),
        );
      } else {
        // For each existing ticket, find the equivalent new ticket based on the entryNumber and update scheduling
        // If the entry number does not exist, mark it for deletion
        existingTickets.forEach((t) => {
          const idx = newTickets.findIndex(
            (nt) => nt.entryNumber === t.entryNumber,
          );
          if (idx === -1) {
            someTicketsToBeDeleted = true;
            t.deleteMe = true;
          } else {
            t.completer = tickets.completer;
            t.remainVisible = tickets.remainVisible;
            t.remainEditable = tickets.remainEditable;
            t.level = tickets.level;
            t.delay = tickets.delay;
            t.repeat = tickets.repeat;
            t.interval = tickets.interval;
            t.intervalUnit = tickets.intervalUnit;
            t.availability = tickets.availability;
            t.availabilityUnit = tickets.availabilityUnit;
            t.allowance = tickets.allowance;
            t.allowanceUnit = tickets.allowanceUnit;
            t.dateAvailable = newTickets[idx].dateAvailable;
            t.dateDue = newTickets[idx].dateDue;
            t.dateExpire = newTickets[idx].dateExpire;
            newTickets[idx].accountedFor = true;
          }
          return t;
        });
        // Now filter out any to-be-deleted tickets and update the others
        existingTickets
          .filter((t) => t.deleteMe === undefined)
          .forEach((t) => {
            dispatch(
              patchCurrentTicket({
                socket,
                requestingUser: currentAuth,
                body: { data: t },
              }),
            );
          });
        // Now delete any tickets if necessary
        if (someTicketsToBeDeleted) {
          existingTickets
            .filter((t) => t.deleteMe)
            .forEach((t) => {
              dispatch(
                deleteCurrentTicket({
                  socket,
                  requestingUser: currentAuth,
                  ticketId: t._id,
                }),
              );
            });
        }
        // If there is an entry number that exists in the new tickets but not in the existing one, add this ticket to the DB
        const unaccountedNewTickets = newTickets.filter(
          (t) => t.accountedFor === undefined,
        );
        if (unaccountedNewTickets.length > 0) {
          dispatch(
            postTicketList({
              socket,
              requestingUser: currentAuth,
              body: {
                data: unaccountedNewTickets,
                meta: { userId: currentUserId, ticketsToCreate: "new" },
              },
            }),
          );
        }
      }
    } else {
      // If it is a new ticket, set the entry number
      if (isNewTicket) {
        const entryNumber = ticketList
          .filter((ticket) => ticket.userId === props.currentConsumer._id)
          .filter(
            (t) =>
              t.studyId === tickets.studyId && t.surveyId === tickets.surveyId,
          );
        newTickets = {
          ...newTickets,
          entryNumber: entryNumber.length + 1,
        };
        // Post the new ticket to the database
        dispatch(
          postTicketList({
            socket,
            requestingUser: currentAuth,
            body: {
              data: [newTickets],
              meta: { userId: currentUserId, ticketsToCreate: "new" },
            },
          }),
        );
      } else {
        // Patch the existing ticket to the database
        dispatch(
          patchCurrentTicket({
            socket,
            requestingUser: currentAuth,
            body: { data: newTickets },
          }),
        );
      }
    }
    // Close the drawer
    dispatch(toggleSecDrawer({ isOpen: false }));
  };
  // -----------------------------------------------
  // Delete a ticket object
  const handleDeleteTicket = () => {
    // Patch the ticket to the database
    dispatch(
      deleteCurrentTicket({
        socket,
        requestingUser: currentAuth,
        ticketId: tickets._id,
      }),
    );
    // Close the drawer
    dispatch(toggleSecDrawer({ isOpen: false }));
  };
  // -----------------------------------------------
  const isOver = (d) => {
    return DateTime.fromISO(d) < DateTime.now();
  };
  // -----------------------------------------------
  const includesToday = (d1, d2) => {
    if (d2 === null) {
      return (
        DateTime.fromISO(d1).startOf("day") <= DateTime.now().startOf("day")
      );
    } else {
      return (
        DateTime.fromISO(d1).startOf("day") <= DateTime.now().startOf("day") &&
        DateTime.fromISO(d2).startOf("day") >= DateTime.now().startOf("day")
      );
    }
  };
  // -----------------------------------------------
  const includesTomorrow = (d1, d2) => {
    if (d2 === null) {
      return (
        DateTime.fromISO(d1).startOf("day") <=
        DateTime.now().plus({ days: 1 }).startOf("day")
      );
    } else {
      return (
        DateTime.fromISO(d1).startOf("day") <=
          DateTime.now().plus({ days: 1 }).startOf("day") &&
        DateTime.fromISO(d2).startOf("day") >=
          DateTime.now().plus({ days: 1 }).startOf("day")
      );
    }
  };
  // -----------------------------------------------
  // Reset the local state when the current user changes
  useEffect(() => {
    setIsOpenDateSelector(false);
    setNewDate(null);
    setMethod("");
    setTickets(null);
    setIsNewTicket(false);
    setCurrentTimepointId(
      props.timepointList &&
        props.timepointList.length > 0 &&
        props.timepointList[0]._id,
    );
  }, [props.currentConsumer, props.timepointList, consumersStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Sets the assorted ticket list when any of the tickets or the availability changes
  useEffect(() => {
    let key;
    setAssortedTicketList(
      ticketList
        .filter((t) => t.userId === props.currentConsumer._id)
        .sort(
          (x, y) =>
            DateTime.fromISO(x.dateAvailable).valueOf() -
            DateTime.fromISO(y.dateAvailable).valueOf(),
        )
        .reduce((obj, t) => {
          let o = JSON.parse(JSON.stringify(t));
          if (o.repeat === 1) {
            o.isSeries = false;
          } else {
            o.isSeries = true;
          }
          if (o.isCompleted) {
            return obj.completed
              ? { ...obj, completed: [...obj.completed, o] }
              : { ...obj, completed: [o] };
          } else if (isOver(o.dateExpire)) {
            return obj.expired
              ? { ...obj, expired: [...obj.expired, o] }
              : { ...obj, expired: [o] };
          } else if (isOver(o.dateDue)) {
            return obj.overdue
              ? { ...obj, overdue: [...obj.overdue, o] }
              : { ...obj, overdue: [o] };
          } else if (includesToday(o.dateAvailable, o.dateDue)) {
            return obj.today
              ? { ...obj, today: [...obj.today, o] }
              : { ...obj, today: [o] };
          } else if (includesTomorrow(o.dateAvailable, o.dateDue)) {
            return obj.tomorrow
              ? { ...obj, tomorrow: [...obj.tomorrow, o] }
              : { ...obj, tomorrow: [o] };
          } else {
            key = o.dateAvailable.substring(0, 10);
            return obj[key]
              ? { ...obj, [key]: [...obj[key], o] }
              : { ...obj, [key]: [o] };
          }
        }, {}),
    );
  }, [props.currentConsumer, ticketList, ticketAvailabilityList]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return props.timepointList.length === 0 ? null : (
    <>
      {/* ================================================== */}
      {/* DIALOG TO CHOOSE A NEW DATE */}
      <Dialog
        open={isOpenDateSelector}
        onClose={() => setIsOpenDateSelector(false)}
      >
        <Typography variant="h4" className="ms-4 mt-4">
          Assign a new date
        </Typography>
        <DialogContent>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={DateTime.fromISO(newDate)}
            onChange={(newValue) => setNewDate(newValue)}
            slotProps={{ textField: { variant: "standard" } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenDateSelector(false)}>Cancel</Button>
          <Button
            onClick={() =>
              method === "assign"
                ? handleAssignTimepointDate()
                : method === "reassign"
                  ? handleReassignTimepointDate()
                  : null
            }
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
      {/* ================================================== */}
      {/* MENU TO ASSIGN TIMEPOINT GROUP */}
      <Menu
        id="assign-timepoint-group"
        anchorEl={anchorElTimepointGroup}
        open={isOpenTimepointGroup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => {
          setAnchorElTimepointGroup(null);
        }}
      >
        <ListSubheader className="mb-1" sx={{ lineHeight: "1rem" }}>
          Assign group
        </ListSubheader>
        {currentStudy.timepointList
          .filter((tp) => tp._id === currentTimepointId)
          .map(
            (tp) =>
              tp.groupIdList &&
              tp.groupIdList.map(
                (groupId) =>
                  currentStudy.groupList.find(
                    (group) => group._id === groupId,
                  ) && (
                    <MenuItem
                      key={groupId}
                      onClick={() => {
                        props.handleMutateCurrentConsumer(
                          "current-enrollment",
                          { groupId },
                        );
                      }}
                    >
                      {
                        currentStudy.groupList.find(
                          (group) => group._id === groupId,
                        ).label
                      }
                    </MenuItem>
                  ),
              ),
          )}
      </Menu>
      {/* ================================================== */}
      {/* DRAWER TO ADD AND EDIT TICKETS */}
      <TopDrawer
        id={"volunteers_content-timepoints"}
        title={isTicketSeries ? "Edit ticket series" : "Edit single ticket"}
        buttons={
          <Button
            disabled={
              ticketsStatus === "loading" ||
              !tickets ||
              tickets.surveyId === null
            }
            color="inherit"
            startIcon={<Save />}
            className="m-2"
            onClick={handlePatchTicket}
          >
            {ticketsStatus === "loading" ? (
              <CircularProgress size="1.5rem" className="text-light" />
            ) : (
              t("Save")
            )}
          </Button>
        }
      >
        {tickets && !isTicketSeries ? (
          <VolunteersEditTicket
            obj={tickets}
            setObj={setTickets}
            handleDeleteObj={handleDeleteTicket}
            currentTimepointId={currentTimepointId}
            canBeDeleted={!isNewTicket}
          />
        ) : tickets && isTicketSeries ? (
          <VolunteersEditTicketSeries obj={tickets} setObj={setTickets} />
        ) : null}
      </TopDrawer>
      {/* ================================================== */}
      {/* MENU TO ADD A TICKET TO A TIMEPOINT  */}
      <Menu
        id="add-ticket-menu"
        anchorEl={anchorElAddMenu}
        open={isOpenAddMenu}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={() => {
          handleToggleAddMenu(null);
        }}
      >
        {currentStudy.timepointList.map((tp) => (
          <MenuItem
            key={tp._id}
            onClick={() => {
              setCurrentTimepointId(tp._id);
              handleAddTicket(tp._id);
              handleToggleAddMenu(null);
            }}
          >
            {tp.label}
          </MenuItem>
        ))}
      </Menu>
      {/* ================================================== */}
      {/* HERE IS THE CONTENT WITH TIMEPOINTS AND TICKETS  */}
      <Card className="mb-3 px-3 py-2">
        <Typography variant="h3">Timepoint enrollment</Typography>
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-around"
          alignItems="flex-start"
          spacing={1}
          className="mt-0"
        >
          {currentStudy.timepointList.map((tp, i) => (
            <React.Fragment key={tp._id}>
              {i > 0 && (
                <Grid item className="flex-grow-1">
                  <hr />
                </Grid>
              )}
              <Grid item sx={{ width: "75px" }}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Tooltip
                      arrow
                      title={
                        isCompletedTimepoint(tp._id)
                          ? `${tp.label} (complete)`
                          : isActiveTimepoint(tp._id) &&
                              isAssignedToTimepointGroup(tp.groupIdList)
                            ? `${tp.label} (active)`
                            : !isAssignedToTimepointGroup(tp.groupIdList)
                              ? `${tp.label} (group not assigned)`
                              : !getTimepointAssignmentStartDate(tp._id)
                                ? `${tp.label} (date not assigned)`
                                : `${tp.label} (later)`
                      }
                      placement="top"
                    >
                      <Chip
                        size="small"
                        label={tp.label}
                        color={
                          isCompletedTimepoint(tp._id)
                            ? "success"
                            : isActiveTimepoint(tp._id) &&
                                isAssignedToTimepointGroup(tp.groupIdList)
                              ? "primary"
                              : "default"
                        }
                        variant={
                          isCompletedTimepoint(tp._id)
                            ? "filled"
                            : isActiveTimepoint(tp._id)
                              ? "filled"
                              : isAssignedToTimepointGroup(tp.groupIdList) &&
                                  getTimepointAssignmentStartDate(tp._id)
                                ? "filled"
                                : "outlined"
                        }
                        icon={
                          isCompletedTimepoint(tp._id) ? <CheckIcon /> : null
                        }
                        sx={{ maxWidth: "75px" }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    {isAssignedToTimepointGroup(tp.groupIdList) &&
                    getTimepointAssignmentStartDate(tp._id) ? (
                      <Tooltip arrow title="re-assign date">
                        <Button
                          size="small"
                          color="inherit"
                          startIcon={<EventRepeatIcon />}
                          className="mt-1"
                          onClick={() => {
                            setMethod("reassign");
                            setCurrentTimepointId(tp._id);
                            setNewDate(getTimepointAssignmentStartDate(tp._id));
                            setIsOpenDateSelector(true);
                          }}
                        >
                          <Typography
                            variant="overline"
                            sx={{ lineHeight: "1" }}
                          >
                            {getTimepointAssignmentStartDate(tp._id).toFormat("d LLL")}
                          </Typography>
                        </Button>
                      </Tooltip>
                    ) : !isAssignedToTimepointGroup(tp.groupIdList) &&
                      getTimepointAssignmentStartDate(tp._id) ? (
                      <Tooltip arrow title="assign group">
                        <Button
                          size="small"
                          color="inherit"
                          startIcon={<ReportGmailerrorredIcon />}
                          className="mt-1"
                          onClick={(e) => {
                            setCurrentTimepointId(tp._id);
                            setAnchorElTimepointGroup(e.currentTarget);
                          }}
                        >
                          <Typography
                            variant="overline"
                            className="opacity-25"
                            sx={{ lineHeight: "1" }}
                          >
                            {getTimepointAssignmentStartDate(tp._id)}
                          </Typography>
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip arrow title="assign start date">
                        <IconButton
                          size="small"
                          color="inherit"
                          className="mt-1"
                          onClick={() => {
                            setMethod("assign");
                            setCurrentTimepointId(tp._id);
                            setNewDate(getTimepointAssignmentStartDate(tp._id));
                            setIsOpenDateSelector(true);
                          }}
                        >
                          <ReportGmailerrorredIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Card>
      <Card className="mb-3 px-3 py-2">
        <List disablePadding>
          <ListItem
            disablePadding
            secondaryAction={
              <Tooltip arrow title="Add ticket" placement="top">
                <IconButton
                  disabled={ticketsStatus === "loading"}
                  edge="end"
                  onClick={(e) => handleToggleAddMenu(e.currentTarget)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemText
              primary={<Typography variant="h3">Scheduled tickets</Typography>}
            />
          </ListItem>
        </List>
        {assortedTicketList.expired && (
          <React.Fragment>
            <Divider className="mt-2" />
            <Typography variant="h6" color="red">
              Expired
            </Typography>
            {assortedTicketList.expired.map((ticket) => (
              <Grid
                key={ticket._id}
                container
                className="list-group-item-action"
              >
                <Grid xs={12} item>
                  <VolunteersContentMeasurementItem
                    isExpired
                    ticket={ticket}
                    currentConsumer={props.currentConsumer}
                    handleEditTicket={handleEditTicket}
                  />
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        )}
        {assortedTicketList.overdue && (
          <React.Fragment>
            <Divider className="mt-2" />
            <Typography variant="h6" color="orangered">
              Overdue
            </Typography>
            {assortedTicketList.overdue.map((ticket) => (
              <Grid
                key={ticket._id}
                container
                className="list-group-item-action"
              >
                <Grid xs={12} item>
                  <VolunteersContentMeasurementItem
                    isOverdue
                    ticket={ticket}
                    currentConsumer={props.currentConsumer}
                    handleEditTicket={handleEditTicket}
                  />
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        )}
        <Divider className="mt-2" />
        <Typography variant="h6">Today</Typography>
        {assortedTicketList.today ? (
          assortedTicketList.today.map((ticket) => (
            <Grid key={ticket._id} container className="list-group-item-action">
              <Grid xs={12} item>
                <VolunteersContentMeasurementItem
                  ticket={ticket}
                  currentConsumer={props.currentConsumer}
                  handleEditTicket={handleEditTicket}
                />
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography variant="caption" color="GrayText">
            No scheduled tickets
          </Typography>
        )}
        <Divider className="mt-2" />
        <Typography variant="h6">Tomorrow</Typography>
        {assortedTicketList.tomorrow ? (
          assortedTicketList.tomorrow.map((ticket) => (
            <Grid key={ticket._id} container className="list-group-item-action">
              <Grid xs={12} item>
                <VolunteersContentMeasurementItem
                  ticket={ticket}
                  currentConsumer={props.currentConsumer}
                  handleEditTicket={handleEditTicket}
                />
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography variant="caption" color="GrayText">
            No scheduled tickets
          </Typography>
        )}
        {Object.keys(assortedTicketList)
          .filter((key) => key[0] === "2") // Only keys that start with 2023-##-##
          .sort()
          .map((key) => (
            <React.Fragment key={key}>
              <Divider className="mt-2" />
              <Typography variant="caption">
                {DateTime.fromISO(key).toFormat("cccc, d LLLL yyyy")}
              </Typography>
              {assortedTicketList[key].map((ticket) => (
                <Grid
                  key={ticket._id}
                  container
                  className="list-group-item-action"
                >
                  <Grid xs={12} item>
                    <VolunteersContentMeasurementItem
                      ticket={ticket}
                      currentConsumer={props.currentConsumer}
                      handleEditTicket={handleEditTicket}
                    />
                  </Grid>
                </Grid>
              ))}
            </React.Fragment>
          ))}
        {assortedTicketList.completed && (
          <React.Fragment>
            <Divider className="mt-2" />
            <Typography variant="h6" color="gray">
              Completed
            </Typography>
            {assortedTicketList.completed.map((ticket) => (
              <Grid
                key={ticket._id}
                container
                className="list-group-item-action opacity-25"
              >
                <Grid xs={12} item>
                  <VolunteersContentMeasurementItem
                    isCompleted
                    ticket={ticket}
                    currentConsumer={props.currentConsumer}
                    handleEditTicket={handleEditTicket}
                  />
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        )}
      </Card>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersContentTimepoints;
