import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { setAlert } from "../redux/reducers/ui";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

import { postFeedback } from "../redux/reducers/feedback";

const FeedbackForm = () => {
  const dispatch = useDispatch();
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  const { t } = useTranslation("components", { keyPrefix: "FeedbackForm" });

  // State management
  const [open, setOpen] = useState(false);
  const [cropOpen, setCropOpen] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [takeScreenshot, setTakeScreenshot] = useState(false);
  const [feedback, setFeedback] = useState("");
  const cropperRef = useRef(null);

  const currentUser = useSelector((state) => state.user.currentUser);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setScreenshot(null);
  };

  // Screenshot logic with useEffect to ensure that the feedback form is closed before taking the screenshot
  useEffect(() => {
    if (takeScreenshot) {
      html2canvas(document.body)
        .then((canvas) => {
          setCropData(canvas.toDataURL("image/png"));
          setTakeScreenshot(false);
          setCropOpen(true);
        })
        .catch((err) => console.error("Error capturing screenshot:", err));
    }
  }, [takeScreenshot]);

  const handleScreenshot = () => {
    setOpen(false); // Close form before taking screenshot
    setTimeout(() => setTakeScreenshot(true), 100); // Delay to ensure form is closed
  };

  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      setScreenshot(cropper.getCroppedCanvas().toDataURL("image/png"));
      setCropOpen(false);
      setOpen(true); // Reopen the form after cropping
    }
  };

  const handleSubmit = async (event) => {
    const feedbackData = {
      email: currentUser?.email || "",
      feedback,
      url: window.location.href,
      browser: navigator.userAgent,
      screenshot,
    };

    setOpen(false);

    try {
      const resultAction = await dispatch(
        postFeedback({ socket, requestingUser: currentAuth, feedbackData })
      );
      if (postFeedback.fulfilled.match(resultAction)) {
        setFeedback("");
        setScreenshot(null);
        setOpen(false);
        dispatch(
          setAlert({
            type: "snackbar",
            variant: "success",
            message: t("success_message"),
            duration: 6000,
          })
        );
      } else {
        throw new Error(resultAction.error.message);
      }
    } catch (error) {
      dispatch(
        setAlert({
          type: "snackbar",
          variant: "error",
          message: "Error submitting feedback: " + error.message,
          duration: 12000,
        })
      );
    }

  };

  return !currentAuth ? null : (
    <>
      <Button
        sx={{
          position: "fixed",
          right: "-40px",
          top: "50%",
          transform: "translateY(-50%) rotate(90deg)",
          zIndex: 5000,
          padding: "10px 20px",
        }}
        variant="contained"
        onClick={handleOpen}
      >
        {t("feedback_button")}
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {t("feedback_title")}
          <IconButton
            aria-label="Close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <TextField
              sx={{ mt: 2 }}
              label={t("your_email")}
              variant="filled"
              color="secondary"
              value={currentUser?.email || ""}
              disabled
              fullWidth
            />
            <TextField
              sx={{ mt: 2 }}
              variant="filled"
              color="secondary"
              fullWidth
              multiline
              rows={4}
              label={t("your_feedback")}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />

            {screenshot ? (
              <Button
                sx={{ mt: 2 }}
                startIcon={<CheckCircleIcon />}
                variant="outlined"
                color="inherit"
                onClick={handleScreenshot}
              >
                {t("screenshot_included")}
              </Button>
            ) : (
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                color="inherit"
                onClick={handleScreenshot}
              >
                {t("include_screenshot")}
              </Button>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            {t("submit_button")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={cropOpen}
        onClose={() => setCropOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {t("crop_title")}
          <IconButton
            aria-label="Close"
            onClick={() => setCropOpen(false)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Cropper
            src={cropData}
            sx={{ height: "100%", width: "100%" }}
            guides={false}
            initialAspectRatio={NaN}
            viewMode={1}
            ref={cropperRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCropOpen(false)}>
            {t("cancel_button")}
          </Button>
          <Button variant="contained" onClick={handleCrop}>
            {t("crop_button")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FeedbackForm;
