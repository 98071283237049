// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
// -------------------------------------------------
// Component elements
import SurveysEditGridItem from "./Surveys_EditGridItem";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Support functions
import { getRGBA } from "../../supportFunc/getRGBA";
import { array2string } from "../../supportFunc/array2string";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Collapse from "@mui/material/Collapse";
// -------------------------------------------------
// Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Link from "@mui/icons-material/Link";
import Save from "@mui/icons-material/Save";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const SurveysContentGridItem = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "surveys.Surveys_ContentGridItem",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const surveysStatus = useSelector((state) => state.surveys.status);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  // -----------------------------------------------
  // Local state
  const hasDependencies =
    props.gridItem.articleList &&
    props.gridItem.articleList.some(
      (article) =>
        (props.dependencies &&
          Object.keys(props.dependencies).some((dep) =>
            dep.includes(article._id)
          )) ||
        (props.scoring &&
          props.scoring.some(
            (score) =>
              (score.arithmetic &&
                score.arithmetic.some((a) =>
                  a.aliasId.includes(article._id)
                )) ||
              (score.grouping && score.grouping.aliasId.includes(article._id))
          ))
    );
  const [obj, setObj] = useState(props.gridItem);
  const [isCollapsed, setIsCollapsed] = useState(
    props.gridItem.options.defaultCollapsed
  );
  const [deleteMenuIsOpen, setDeleteMenuIsOpen] = useState(false);
  const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(null);
  const backgroundColor = getRGBA(
    props.gridItem.layout.bgColor,
    props.gridItem.layout.bgOpacity,
    props.gridItem.options.noCard
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles opening the top drawer for changing survey info
  const handleEdit = (id) => {
    dispatch(toggleSecDrawer({ isOpen: true, id }));
  };
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setObj(props.gridItem);
    }
  }, [props.gridItem, secDrawerIsOpen]);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <Grid
      item
      id="grid-item"
      xs={props.gridItem.layout.width.xs}
      sm={props.gridItem.layout.width.sm}
      md={props.gridItem.layout.width.md}
      lg={props.gridItem.layout.width.lg}
      xl={props.gridItem.layout.width.xl}
    >
      {/* ================================================== */}
      {/* TOP DRAWER */}
      {obj && (
        <TopDrawer
          id={obj._id}
          title={t("Edit box")}
          buttons={
            <Button
              disabled={surveysStatus === "loading" || props.disabled}
              color="inherit"
              startIcon={<Save />}
              className="m-2"
              onClick={() =>
                props.handleMutateCurrentSurvey("gridItem", obj, {
                  page: props.pageIdx,
                  gridContainer: props.gridContainerIdx,
                  gridItem: props.gridItemIdx,
                })
              }
            >
              {surveysStatus === "loading" ? (
                <CircularProgress size="1.5rem" className="text-light" />
              ) : (
                t("Save")
              )}
            </Button>
          }
        >
          <SurveysEditGridItem
            obj={obj}
            setObj={setObj}
            aliasList={props.aliasList}
            dependencies={props.dependencies}
            scoring={props.scoring}
          />
        </TopDrawer>
      )}
      {/* ================================================== */}
      {/* SETTINGS*/}
      <Grid
        container
        alignItems="end"
        justifyContent="space-between"
        className="border border-grey border-dashed rounded"
        sx={{ padding: "2px" }}
      >
        {/* ================================================== */}
        {/* NUMBER */}
        <Grid item sx={{ height: "23px" }}>
          <Typography variant="overline" color="slategray" className="ps-2">
            {`Article box ${props.gridItemIdx + 1} `}
          </Typography>
        </Grid>
        {!props.disabled && (
          <Grid item>
            {/* ================================================== */}
            {/* EDIT BUTTONS */}
            <Tooltip arrow title={t("Edit")} placement="top">
              <span>
                <IconButton
                  disabled={surveysStatus === "loading"}
                  edge="end"
                  className="me-1 bg-transparant-white-33"
                  onClick={() => handleEdit(props.gridItem._id)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip arrow title={t("Copy")} placement="top">
              <span>
                <IconButton
                  disabled={surveysStatus === "loading"}
                  edge="end"
                  className="me-1 bg-transparant-white-33"
                  onClick={() =>
                    props.handleCopy(
                      props.pageIdx,
                      props.gridContainerIdx,
                      props.gridItemIdx,
                      props.gridItem
                    )
                  }
                >
                  <ContentCopy />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title={
                props.gridItemIdx === 0 ? t("Can't move up") : t("Move up")
              }
              placement="top"
            >
              <span>
                <IconButton
                  disabled={
                    surveysStatus === "loading" || props.gridItemIdx === 0
                  }
                  edge="end"
                  className="me-1 bg-transparant-white-33"
                  onClick={() =>
                    props.handleMove(
                      props.pageIdx,
                      props.gridContainerIdx,
                      props.gridItemIdx,
                      -1
                    )
                  }
                >
                  <ArrowUpward />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title={props.isLast ? t("Can't move down") : t("Move down")}
              placement="top"
            >
              <span>
                <IconButton
                  disabled={surveysStatus === "loading" || props.isLast}
                  edge="end"
                  className="me-1 bg-transparant-white-33"
                  onClick={() =>
                    props.handleMove(
                      props.pageIdx,
                      props.gridContainerIdx,
                      props.gridItemIdx,
                      1
                    )
                  }
                >
                  <ArrowDownward />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              arrow
              title={hasDependencies ? t("Can't delete") : t("Delete")}
              placement="top"
            >
              <span>
                <IconButton
                  disabled={surveysStatus === "loading" || hasDependencies}
                  edge="end"
                  className="me-0 bg-transparant-white-33"
                  onClick={(e) => {
                    setDeleteMenuIsOpen(true);
                    setDeleteMenuAnchor(e.currentTarget);
                  }}
                >
                  <DeleteForever />
                </IconButton>
              </span>
            </Tooltip>
            <Menu
              anchorEl={deleteMenuAnchor}
              open={deleteMenuIsOpen}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={() => {
                setDeleteMenuIsOpen(false);
                setDeleteMenuAnchor(null);
              }}
            >
              <MenuItem
                onClick={() =>
                  props.handleDelete(
                    props.pageIdx,
                    props.gridContainerIdx,
                    props.gridItemIdx
                  )
                }
              >
                {surveysStatus === "loading" ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  "Delete"
                )}
              </MenuItem>
            </Menu>
          </Grid>
        )}
        <Grid container>
          <Grid
            item
            xs={12}
            className={props.gridItem.options.isCollapsable ? "px-2" : ""}
          >
            <Grid
              container
              className={
                props.gridItem.options.isCollapsable && !isCollapsed
                  ? "py-1 bg-gradient-secondary rounded-top"
                  : props.gridItem.options.isCollapsable && isCollapsed
                  ? "py-1 bg-gradient-secondary rounded"
                  : ""
              }
              onClick={(e) =>
                props.gridItem.options.isCollapsable &&
                setIsCollapsed(!isCollapsed)
              }
            >
              {/* ================================================== */}
              {/* TITLE */}
              {((props.gridItem.title && props.gridItem.title.text) ||
                props.gridItem.logic) && (
                <Grid
                  item
                  className="flex-grow-1"
                  sx={{
                    paddingLeft: `${props.gridItem.layout.padding.left}rem`,
                    paddingRight: `${props.gridItem.layout.padding.right}rem`,
                  }}
                >
                  {props.gridItem.title && (
                    <Typography
                      variant={props.gridItem.title.variant}
                      color={props.gridItem.title.color}
                      align={props.gridItem.title.align}
                    >
                      {props.gridItem.title.text}
                    </Typography>
                  )}
                  {props.gridItem.logic && (
                    <Chip
                      icon={<Link />}
                      label={`conditional on ${array2string(
                        props.gridItem.logic.map((logicGroup) =>
                          logicGroup.itemList.map(
                            (logicItem) =>
                              props.aliasList[logicItem.condition.aliasId].alias
                          )
                        )
                      )}`}
                      size="small"
                      variant="outlined"
                      color="info"
                      className={
                        props.gridItem.title && props.gridItem.title.text
                          ? "ms-3 border-0"
                          : "border-0"
                      }
                    />
                  )}
                </Grid>
              )}
              {props.gridItem.options.isCollapsable && (
                <Grid item className="flex-grow-0 pe-2">
                  <IconButton>
                    {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Collapse
              in={!isCollapsed || !props.gridItem.options.isCollapsable}
            >
              {/* ================================================== */}
              {/* CONTENT */}
              {props.gridItem.options.isCard ? (
                <Card
                  className={`${props.gridItem.layout.classNames} ${
                    props.gridItem.options.hasShadow
                      ? "w-100 shadow"
                      : "w-100 shadow-none"
                  }`}
                  sx={{
                    backgroundColor:
                      backgroundColor === null ? "#FFFFFF" : backgroundColor,
                    minHeight: `${props.gridItem.layout.minHeight}px`,
                    paddingLeft: `${props.gridItem.layout.padding.left}rem`,
                    paddingRight: `${props.gridItem.layout.padding.right}rem`,
                    paddingTop: `${props.gridItem.layout.padding.top}rem`,
                    paddingBottom: `${props.gridItem.layout.padding.bottom}rem`,
                    backgroundImage: `url("${props.gridItem.layout.bgImage}")`,
                    backgroundOrigin: props.gridItem.layout.bgImageOrigin,
                    backgroundPosition: props.gridItem.layout.bgImagePosition,
                    backgroundRepeat: props.gridItem.layout.bgImageRepeat,
                    backgroundSize: props.gridItem.layout.bgImageSize,
                  }}
                >
                  {props.children}
                </Card>
              ) : (
                <Box
                  id="box-item"
                  className={`rounded w-100 ${props.gridItem.layout.classNames}`}
                  sx={{
                    backgroundColor:
                      backgroundColor === null
                        ? "rgba(0, 0, 0, 0)"
                        : backgroundColor,
                    minHeight: `${props.gridItem.layout.minHeight}px`,
                    paddingLeft: `${props.gridItem.layout.padding.left}rem`,
                    paddingRight: `${props.gridItem.layout.padding.right}rem`,
                    paddingTop: `${props.gridItem.layout.padding.top}rem`,
                    paddingBottom: `${props.gridItem.layout.padding.bottom}rem`,
                    backgroundImage: `url("${props.gridItem.layout.bgImage}")`,
                    backgroundOrigin: props.gridItem.layout.bgImageOrigin,
                    backgroundPosition: props.gridItem.layout.bgImagePosition,
                    backgroundRepeat: props.gridItem.layout.bgImageRepeat,
                    backgroundSize: props.gridItem.layout.bgImageSize,
                  }}
                >
                  {props.children}
                </Box>
              )}
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default SurveysContentGridItem;
